<template>
  <span v-if="hasPerm('youth.view_seance')">
    <loading-gif :loading-name="seancesListLoadingName"></loading-gif>
      <div class="sub-line">
        <b-row v-if="seanceType && !isLoading(seancesListLoadingName)">
          <b-col cols="8">
            <div v-if="!isFilterDefined" class="empty-text">
              Veuillez sélectionner une période
            </div>
            <div v-else-if="filteredSeances.length === 0" class="empty-text">
              Aucune séance ne correspond à ce filtre
            </div>
            <div v-else class="sub-title">
              <counter-label :counter="filteredSeances.length" label="séance"></counter-label>
            </div>
          </b-col>
          <b-col cols="4" class="text-right">
            <a
              href
              @click.prevent="showOpeningHoursModal('', null)"
              v-b-tooltip="'Modifier les horaires d\'ouverture'"
              class="btn btn-secondary"
              v-if="hasPerm('youth.change_openinghours')"
            >
              <i class="fas fa-clock"></i>
            </a>
            <a
              v-if="canCloneSeances"
              href
              @click.prevent="cloneSeances()"
              class="btn btn-secondary"
              :class="{ disabled: periods.length !== 1, }"
              v-b-tooltip="'Cloner sur un autre accueil de loisirs'"
            >
              <i class="fa fa-clone"></i>
            </a>
            <a
              v-if="canAddSeances"
              href
              @click.prevent="addManySeances()"
              class="btn btn-primary ut-add-many"
              :class="{ disabled: periods.length !== 1, }"
              v-b-tooltip="'Ajout de plusieurs séances'"
            >
              <i class="fa fa-calendar-plus"></i>
            </a>
            <a
              v-if="canAddSeances"
              href
              @click.prevent="addNewSeance()"
              class="btn btn-secondary ut-add-one"
              :class="{ disabled: periods.length !== 1, }"
              v-b-tooltip="'Ajout d\'une séance'"
            >
              <i class="fa fa-plus"></i>
            </a>
            <a
              v-if="canDeleteSeance"
              href
              @click.prevent="deleteManySeances()"
              class="btn btn-danger"
              :class="{ disabled: periods.length !== 1, }"
              v-b-tooltip="'Suppression multiple'"
            >
              <i class="fas fa-window-close"></i>
            </a>
          </b-col>
        </b-row>
      </div>
    <div v-if="isFilterDefined && !isLoading(seancesListLoadingName)">
      <div v-for="period of filteredPeriods" :key="period.id" class="seance-period-list-item">
        <div class="seance-period-header">
          <b-row>
            <b-col cols="9">
              {{ period.name }}
            </b-col>
            <b-col class="text-right">
              <a
                href
                @click.prevent="showOpeningHoursModal('', period)"
                v-b-tooltip="'Modifier les horaires d\'ouverture pour ' + period.name"
                class="seance-button"
                :class="isCustomOpeningHours(period, null) ? 'seance-button-custom-value' : 'seance-button-no-value'"
                v-if="hasPerm('youth.change_openinghours')"
              >
                <i class="fas fa-clock"></i>
              </a>
            </b-col>
          </b-row>
        </div>
        <div v-for="seance of getPeriodFilteredSeances(period)" :key="seance.id" class="seance-list-item">
          <b-row :class="{ faded: seance.parent }">
            <b-col cols="3">
              {{ seance.date | dateToString('ddd ll') }}
            </b-col>
            <b-col cols="5">
              {{ seance.getShortName() }}
              <span class="small-badge badge-light" :title="seance.code">{{ seance.getCodeName() }}</span>&nbsp;
              <span
                v-if="seance.parent" class="badge badge-dark"
                title="Un enfant est inscrit automatiquement lorsqu'il est inscrite à la séance liée"
              >
                Séance liée
              </span>
              <div v-if="seance.parent" class="small-text">
                dépend de la séance {{ getSeanceShortName(seance.parent) }}
              </div>
            </b-col>
            <b-col cols="4" class="text-right">
              <a
                href
                @click.prevent="showOpeningHoursModal(seance.date, period)"
                v-b-tooltip="'Modifier les horaires d\'ouverture pour le ' + dateToString(seance.date)"
                class="seance-button"
                :class="isCustomOpeningHours(period, seance.date) ? 'seance-button-custom-value' : 'seance-button-no-value'"
                v-if="hasPerm('youth.change_openinghours')"
              >
                <i class="fas fa-clock"></i>
              </a>
              <a
                href
                v-if="canViewWorkshops(seance) && !seance.fixedFee"
                @click.prevent="viewWorkshops(seance)"
                class="seance-button ut-view-workshops"
                v-b-tooltip.hover.bottom="seanceWorkshopsTooltip(seance)"
                :class="seanceWorkshopsButtonClass(seance)"
              >
                <i class="fa fa-theater-masks"></i>
              </a>
              <a
                href
                v-if="hasFixedFee()"
                @click.prevent="viewFixedFee(seance)"
                class="seance-button seance-button-no-value"
                v-b-tooltip.hover.bottom="seanceFixedFeeTooltip(seance)"
                :class="seanceFixedFeeButtonClass(seance)"
              >
                <i class="fa fa-boxes"></i>
              </a>
              <a
                href
                v-if="showWelfare && canViewWelfare(seance)"
                @click.prevent="viewWelfare(seance)"
                class="seance-button ut-view-welfare"
                v-b-tooltip.hover.bottom="seanceWelfareTooltip(seance)"
                :class="seanceWelfareButtonClass(seance)"
              >
                <i class="fa fa-handshake"></i>
              </a>
              <a
                href
                v-if="canViewLimits(seance)"
                @click.prevent="viewLimits(seance)"
                class="seance-button ut-view-limits"
                v-b-tooltip.hover.bottom="seanceLimitsTooltip(seance)"
                :class="seanceLimitsButtonClass(seance)"
              >
                <i class="fa fa-users"></i>
              </a>
              <a
                href
                class="seance-button seance-button-disabled"
                title="Les limites d'enfants sont à définir sur la séance principale"
                v-b-tooltip.hover.bottom
                v-else-if="canViewDisabledLimits(seance)"
                @click.prevent="noop()"
              >
                <i class="fa fa-users"></i>
              </a>
              <a
                href
                v-if="canViewTariff(seance)"
                @click.prevent="viewTariff(seance)"
                class="seance-button ut-view-tariff"
                v-b-tooltip.hover.bottom="seanceTariffTooltip(seance)"
                :class="seanceTariffButtonClass(seance)"
              >
                <i class="fa fa-euro"></i>
              </a>
              <a
                href
                v-if="canChangeSeance(seance)"
                @click.prevent="editSeance(seance)"
                class="seance-button seance-button-edit ut-edit-seance"
                v-b-tooltip.hover.bottom="'Modifier'"
              >
                <i class="fa fa-edit"></i>
              </a>
              <a
                href
                v-if="canViewSeance(seance)"
                @click.prevent="editSeance(seance)"
                class="seance-button seance-button-edit"
                v-b-tooltip.hover.bottom="'Voir'"
              >
                <i class="fa fa-eye"></i>
              </a>
              <a
                v-if="canDeleteSeance(seance)"
                href
                @click.prevent="deleteSeance(seance)"
                class="seance-button seance-button-delete ut-delete-seance"
                v-b-tooltip.hover.bottom="'Supprimer'"
              >
                <i class="fa fa-times"></i>
              </a>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <opening-hours-modal
      modal-id="bv-opening-hours-modal"
      :seance-type="seanceType"
      :youth-home="youthHome"
      :period="selectedPeriod"
      :date="selectedDate"
      @refresh="loadAll()"
    ></opening-hours-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions, mapMutations } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendMixin } from '@/mixins/backend'
import { makeSeance, SeanceLock } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import OpeningHoursModal from '@/components/Youth/OpeningHoursModal'
import { distinct, areSameArrays } from '@/utils/arrays'
import { compareNumbers } from '@/utils/sorting'
import { dateToString } from '@/filters/texts'

export default {
  name: 'SeancesList',
  components: {
    OpeningHoursModal,
    CounterLabel,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    youthHome: Object,
    seanceType: Object,
    periods: Array,
    seances: {
      type: Array,
      defaultValue: [],
    },
    allTemplates: {
      type: Array,
      defaultValue: [],
    },
    templates: {
      type: Array,
      defaultValue: [],
    },
    refreshCounter: Number,
    showWelfare: {
      type: Boolean,
      defaultValue: true,
    },
  },
  data() {
    return {
      seancesListLoadingName: 'seances-list',
      selectedPeriod: null,
      selectedDate: '',
      customsOpeningHours: new Map(),
    }
  },
  watch: {
    youthHome: function() { this.loadAll() },
    seanceType: function() { this.loadAll() },
    periods: function() { this.loadAll() },
    allTemplates: function() { },
    templates: function() { },
    seances: function() { },
    showWelfare: function() {},
    refreshCounter: function(value) {
      if (value > 0) {
        this.loadAll()
      }
    },
  },
  computed: {
    isFilterDefined() {
      return (this.youthHome && this.seanceType && this.periods && this.periods.length)
    },
    filteredPeriods() {
      return distinct(this.filteredSeances.map(elt => elt.period))
    },
    filteredSeances() {
      return this.seances.filter(
        elt => {
          return (
            (this.codes.indexOf(elt.getCodeName()) >= 0) || (this.allCodes.indexOf(elt.getCodeName()) < 0)
          )
        }
      )
    },
    codes() {
      return this.templates.map(elt => elt.code)
    },
    allCodes() {
      return this.allTemplates.map(elt => elt.code)
    },
    canAddSeances() {
      return this.hasPerm('youth.add_seance')
    },
    canCloneSeances() {
      return this.hasPerm('youth.add_seance') && (this.youthHome.canCloneSeancesOn.length > 0)
    },
  },
  methods: {
    dateToString,
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    getPeriodFilteredSeances(period) {
      return this.filteredSeances.filter(elt => elt.period.id === period.id)
    },
    canViewWorkshops(seance) {
      return (
        this.hasPerm('youth.view_workshop') &&
        !(seance.excursion && seance.parent) &&
        (seance.lock !== SeanceLock.Total)
      )
    },
    canViewWelfare(seance) {
      return (
        this.hasPerm('tariffs.view_welfare') &&
        !(seance.excursion && seance.parent) &&
        !seance.isReward &&
        (seance.lock !== SeanceLock.Total)
      )
    },
    canViewLimits(seance) {
      return (
        this.hasPerm('youth.view_seancelimit') &&
        !(seance.parent) &&
        (seance.lock !== SeanceLock.Total)
      )
    },
    canViewDisabledLimits(seance) {
      return (
        this.hasPerm('youth.view_seancelimit') &&
        (seance.parent && !seance.excursion) &&
        (seance.lock !== SeanceLock.Total)
      )
    },
    canViewTariff(seance) {
      return (
        this.hasPerm('youth.view_seancetariff') &&
        !(seance.excursion && seance.parent) &&
        (seance.lock !== SeanceLock.Total)
      )
    },
    canChangeSeance(seance) {
      return (
        this.hasPerm('youth.change_seance') &&
        !(seance.excursion && seance.parent) &&
        (seance.lock !== SeanceLock.Total)
      )
    },
    canViewSeance(seance) {
      return (
        this.hasPerm('youth.view_seance') &&
        !(seance.excursion && seance.parent) &&
        (seance.lock === SeanceLock.Total)
      )
    },
    canDeleteSeance(seance) {
      return this.hasPerm('youth.delete_seance') && (seance.lock !== SeanceLock.Total)
    },
    hasFixedFee() {
      return this.seances.filter(elt => elt.fixedFee).length > 0
    },
    async loadAll() {
      await this.loadSeances()
      await this.loadCustomOpeningHours()
    },
    async loadSeances() {
      if (this.youthHome && this.seanceType && this.periods && this.periods.length) {
        this.startLoading(this.seancesListLoadingName)
        let url = '/api/youth/seances/'
        url += '?youth_home=' + this.youthHome.id
        url += '&seance_type=' + this.seanceType.id
        url += '&periods=' + this.periods.map(elt => ('' + elt.id)).join(',')
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          let seances = resp.data.map(elt => makeSeance(elt))
          this.$emit('seances-loaded', { seances: seances, })
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.seancesListLoadingName)
      }
    },
    async loadCustomOpeningHours() {
      if (this.youthHome && this.seanceType && this.periods && this.periods.length) {
        let url = '/api/youth/custom-opening-hours/'
        url += this.youthHome.id + '/'
        url += this.seanceType.id + '/'
        url += '?periods=' + this.periods.map(elt => '' + elt.id).join('-')
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          const customsOpeningHours = new Map()
          for (const elt of resp.data) {
            let dates = []
            if (customsOpeningHours.has(elt.period)) {
              dates = customsOpeningHours.get(elt.period)
            }
            dates.push(elt.date)
            customsOpeningHours.set(elt.period, dates)
          }
          this.customsOpeningHours = customsOpeningHours
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    isCustomOpeningHours(period, date) {
      if (this.customsOpeningHours.has(period.id)) {
        const dates = this.customsOpeningHours.get(period.id)
        const fmtDate = date ? dateToString(date, 'YYYY-MM-DD') : null
        return dates.indexOf(fmtDate) >= 0
      }
      return false
    },
    editSeance(seance) {
      this.$emit('edit-seance', seance)
    },
    viewTariff(seance) {
      this.$emit('view-tariff', seance)
    },
    getSeanceTemplate(seance) {
      let seanceCode = seance.getCodeName()
      let matchingTemplates = this.templates.filter(
        template => { return template.code === seanceCode }
      )
      if (matchingTemplates.length) {
        return matchingTemplates[0]
      }
      return null
    },
    seanceTariffButtonClass(seance) {
      if (seance.tariff === 0) {
        return 'seance-button-no-value'
      } else {
        let template = this.getSeanceTemplate(seance)
        if (
          template &&
          (seance.tariff === template.tariff) &&
          ((template.scale === 0) || (seance.scale === template.scale))
        ) {
          return 'seance-button-template-value'
        } else {
          return 'seance-button-custom-value'
        }
      }
    },
    seanceTariffTooltip(seance) {
      if (seance.tariff === 0) {
        return 'Aucun tarif'
      } else {
        let template = this.getSeanceTemplate(seance)
        if (
          template &&
          (seance.tariff === template.tariff) &&
          ((template.scale === 0) || (seance.scale === template.scale))
        ) {
          return 'Tarif du modèle'
        } else {
          return 'Tarif personnalisé'
        }
      }
    },
    viewLimits(seance) {
      this.$emit('view-limits', seance)
    },
    seanceLimitsButtonClass(seance) {
      if ((seance.globalLimits + seance.seanceLimits) === 0) {
        return 'seance-button-no-value'
      } else {
        if (seance.seanceLimits === 0) {
          return 'seance-button-template-value'
        } else {
          return 'seance-button-custom-value'
        }
      }
    },
    seanceLimitsTooltip(seance) {
      if ((seance.globalLimits + seance.seanceLimits) === 0) {
        return 'Aucune limite'
      } else {
        if (seance.seanceLimits === 0) {
          return 'Limites globales'
        } else {
          return 'Limites sur la séance'
        }
      }
    },
    viewWorkshops(seance) {
      this.$emit('view-workshops', seance)
    },
    seanceFixedFeeButtonClass(seance) {
      if (seance.fixedFee) {
        return 'seance-button-custom-value'
      } else {
        return 'seance-button-no-value'
      }
    },
    seanceFixedFeeTooltip(seance) {
      if (seance.fixedFee) {
        return 'Voir le forfait'
      } else {
        return 'Voir les forfaits associés'
      }
    },
    seanceWorkshopsButtonClass(seance) {
      if (seance.workshops.length === 0) {
        return 'seance-button-no-value'
      } else {
        let template = this.getSeanceTemplate(seance)
        if (template) {
          let workshops = seance.workshops.map(elt => elt.id)
          let templateWorkshops = template.workshops.map(elt => elt.id)
          if (areSameArrays(workshops, templateWorkshops, compareNumbers)) {
            return 'seance-button-template-value'
          }
        }
        return 'seance-button-custom-value'
      }
    },
    seanceWorkshopsTooltip(seance) {
      if (seance.workshops.length === 0) {
        return 'Aucun atelier'
      } else {
        let template = this.getSeanceTemplate(seance)
        if (template) {
          let workshops = seance.workshops.map(elt => elt.id)
          let templateWorkshops = template.workshops.map(elt => elt.id)
          if (areSameArrays(workshops, templateWorkshops, compareNumbers)) {
            return 'Ateliers du modèle'
          }
        }
        return 'Ateliers de la séance'
      }
    },
    viewWelfare(seance) {
      this.$emit('view-welfare', seance)
    },
    viewFixedFee(seance) {
      this.$emit('view-fixed-fee', seance)
    },
    seanceWelfareButtonClass(seance) {
      if (seance.welfare.length === 0) {
        return 'seance-button-no-value'
      } else {
        let template = this.getSeanceTemplate(seance)
        if (template) {
          let welfare = seance.welfare.map(elt => elt.id)
          let templateWelfare = template.welfare.map(elt => elt.id)
          if (areSameArrays(welfare, templateWelfare, compareNumbers)) {
            return 'seance-button-template-value'
          }
        }
        return 'seance-button-custom-value'
      }
    },
    seanceWelfareTooltip(seance) {
      if (seance.welfare.length === 0) {
        return 'Aucune aide'
      } else {
        let template = this.getSeanceTemplate(seance)
        if (template) {
          let welfare = seance.welfare.map(elt => elt.id)
          let templateWelfare = template.welfare.map(elt => elt.id)
          if (areSameArrays(welfare, templateWelfare, compareNumbers)) {
            return 'Aides du modèle'
          }
        }
        return 'Aides de la séance'
      }
    },
    deleteSeance(seance) {
      this.$emit('delete-seance', seance)
    },
    addNewSeance() {
      this.$emit('add-one-seance')
    },
    deleteManySeances() {
      this.$emit('delete-many-seances')
    },
    cloneSeances() {
      if (this.periods.length === 1) {
        this.$emit('clone-seances')
      }
    },
    addManySeances() {
      this.$emit('add-many-seances')
    },
    showOpeningHoursModal(date, period) {
      this.selectedDate = date
      this.selectedPeriod = period
      this.$nextTick(
        async function() {
          this.$bvModal.show('bv-opening-hours-modal')
        }
      )
    },
    getSeanceShortName(seanceId) {
      const seance = this.seances.find(elt => elt.id === seanceId)
      if (seance) {
        return seance.getShortName() + ' du ' + seance.getShortDateName()
      }
      return ''
    },
    noop() {
      // no operations
    },
  },
  mounted() {
    this.loadAll()
  },
}
</script>
<style lang="less">
.seance-list-item {
  padding: 2px;
  border-bottom: solid 1px #d0d0d0;
}
.seance-list-item:last-of-type {
  border-bottom: none
}
.seance-list-item:nth-of-type(even) {
  background: #fff;
}
a.seance-button {
  border-radius: 50%;
  padding: 4px;
  min-width: 28px;
  height: 28px;
  font-size: 12px;
  color: #000 !important;
  display: inline-block;
  text-align: center;
  border: solid 1px #888;
  margin: 1px 2px;
}
a.seance-button-no-value {
  background: #f4e1b9;
}
a.seance-button-template-value {
  background: #adbbf5;
}
a.seance-button-custom-value {
  background: #e9a0cf;
}
a.seance-button-edit {
  background: #d0d0d0;
  color: #000 !important;
}
a.seance-button-delete {
  background: #f78888;
  color: #000 !important;
}
a.seance-button-disabled {
  background: #eee;
  color: #aaa !important;
  border-color: #eee;
}
.sub-title {
  font-weight: bold;
  font-size: 1.2em;
  padding-top: 5px;
}
.sub-line {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: solid 1px #d0d0d0;
}
.sub-line .btn {
  margin-left: 5px;
}
.faded {
  color: #888;
}
.seance-period-list-item {
  padding: 2px 5px;
  border: solid 1px #e0e0e0;
  margin-bottom: 5px;
}
.seance-period-header {
  background: #e0e0e0;
  padding: 2px 5px;
  font-weight: bold;
}
</style>
