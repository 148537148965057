<template>
  <span>
    <div class="select-bar">
      <b-row v-if="title">
        <b-col>
          <h2>{{ title }}</h2>
        </b-col>
      </b-row>
      <loading-gif short :loading-name="loadingName"></loading-gif>
      <b-row v-if="!isLoading(loadingName)">
        <b-col :class="stacked ? 'col-stacked' : ''">
          <b-form-group
            :label="youthHomeLabel"
            label-for="youth-home-filter"
            description=""
          >
            <b-form-select
              :id="'youth-home-filter' + id"
              v-model="selectedYouthHome"
              v-if="!multi"
            >
              <b-form-select-option :value="elt" v-for="elt in youthHomes" :key="elt.id">
                {{ elt.name }}
              </b-form-select-option>
            </b-form-select>
            <check-box-select
              v-if="multi"
              :initial-value="selectedYouthHomes"
              :choices="youthHomes"
              inline
              @changed="youthHomesChanged($event)"
              :id="'youth-homes' + id"
            ></check-box-select>
          </b-form-group>
        </b-col>
        <b-col :class="stacked ? 'col-stacked' : ''">
          <b-form-group
            label="Type de séance"
            :label-for="'seance-type-filter' + id"
            v-if="showTypes"
            description=""
          >
            <b-form-select
              v-if="!multi"
              :id="'seance-type-filter' + id"
              v-model="selectedSeanceType"
            >
              <b-form-select-option :value="elt" v-for="elt in seanceTypes" :key="elt.id">
                {{ elt.name }}
              </b-form-select-option>
            </b-form-select>
            <check-box-select
              v-if="multi"
              :initial-value="selectedSeanceTypes"
              :choices="seanceTypes"
              inline
              @changed="seancesTypesChanged($event)"
              :id="'seance_types' + id"
            ></check-box-select>
          </b-form-group>
        </b-col>
        <b-col v-if="singlePeriod" :class="stacked ? 'col-stacked' : ''">
          <b-form-group
            label="Période"
            :label-for="'period-filter' + id"
            v-if="showPeriods"
            description=""
          >
            <b-form-select
              :id="'period-filter' + id"
              v-model="selectedPeriod"
            >
              <b-form-select-option :value="elt" v-for="elt in periods" :key="elt.id">
                {{ elt.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="showPeriods && !singlePeriod">
        <b-col>
          <b-form-group
            label="Période"
            label-for="period-filter"
            description=""
          >
            <check-box-select
              :choices="periods"
              :initial-value="selectedPeriods"
              :description-callback="getPeriodTimeframe"
              inline
              @changed="periodsChanged($event)"
              :id="'periods' + id"
            ></check-box-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { BackendMixin } from '@/mixins/backend'
import { mapActions, mapMutations } from 'vuex'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import LoadingGif from '@/components/Controls/LoadingGif'
import { makeSeancePeriod, makeSeanceType, makeYouthHome } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import store from '@/store'
import { existsIn, intersection } from '@/utils/arrays'
import { compareNumbers, compareDays } from '@/utils/sorting'

export default {
  name: 'SeancesListFilter',
  components: {
    CheckBoxSelect,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    multi: {
      type: Boolean,
      default: false,
    },
    singlePeriod: {
      type: Boolean,
      default: false,
    },
    initHome: {
      type: Number,
      default: 0,
    },
    initType: {
      type: Number,
      default: 0,
    },
    initPeriod: {
      type: Number,
      default: 0,
    },
    initHomes: {
      type: Array,
      defaultValue: [],
    },
    initTypes: {
      type: Array,
      defaultValue: [],
    },
    initPeriods: {
      type: Array,
      defaultValue: [],
    },
  },
  data() {
    return {
      selectedYouthHome: null,
      selectedSeanceType: null,
      selectedPeriod: null,
      selectedPeriods: [],
      youthHomes: [],
      allSeanceTypes: [],
      allPeriods: [],
      selectedYouthHomes: [],
      selectedSeanceTypes: [],
    }
  },
  watch: {
    selectedYouthHome: function() {
      this.selectedSeanceType = null
      // this.selectedPeriods = []
      this.selectedPeriod = null
      this.emitChanged()
    },
    selectedSeanceType: function() {
      // this.selectedPeriods = []
      this.selectedPeriod = null
      this.emitChanged()
    },
    selectedPeriod: function() {
      this.emitChanged()
    },
    selectedYouthHomes: function() {
      this.emitChanged()
    },
    selectedSeanceTypes: function() {
      this.emitChanged()
    },
    selectedPeriods: function() {
      this.emitChanged()
    },
    seanceTypes: function() {
      if (this.multi) {
        // vérifie les types sélectionnés sont toujours visibles
        let selectedSeanceTypes = []
        let seanceTypeIds = this.seanceTypes.map(elt => elt.id)
        for (const elt of this.selectedSeanceTypes) {
          if (existsIn([elt.id], seanceTypeIds)) {
            selectedSeanceTypes.push(elt)
          }
        }
        this.selectedSeanceTypes = selectedSeanceTypes
        if (this.seanceTypes.length === 1 && this.selectedYouthHomes.length > 0) {
          this.selectedSeanceTypes = this.seanceTypes
        }
      } else {
        this.selectedSeanceType = null
        if (this.seanceTypes.length === 1 && this.selectedYouthHome) {
          this.selectedSeanceType = this.seanceTypes[0]
        }
      }
    },
    periods: function() {
      if (this.multi) {
        // vérifie les types sélectionnés sont toujours visibles
        let selectedPeriods = []
        let periodIds = this.periods.map(elt => elt.id)
        for (const elt of this.selectedPeriods) {
          if (existsIn([elt.id], periodIds)) {
            selectedPeriods.push(elt)
          }
        }
        this.selectedPeriods = selectedPeriods
        if (this.periods.length === 1) {
          this.selectedPeriods = this.periods
        }
      } else {
        this.selectedPeriods = []
        if (this.periods.length === 1) {
          this.selectedPeriods = this.periods
        }
      }
    },
    initHome() {
      if (this.initHome) {
        const homes = this.youthHomes.filter(elt => +this.initHome === elt.id)
        if (homes.length === 1) {
          this.selectedYouthHome = homes[0]
        }
      }
    },
    initType() {
      if (this.initType) {
        const seanceTypes = this.seanceTypes.filter(elt => +this.initType === elt.id)
        if (seanceTypes.length === 1) {
          this.selectedSeanceType = seanceTypes[0]
        }
      }
    },
    initPeriod() {
      if (this.initPeriod) {
        const periods = this.periods.filter(elt => +this.initPeriod === elt.id)
        if (periods.length === 1) {
          this.selectedPeriod = periods[0]
        }
      }
    },
    initHomes() {
      if (this.initHomes && this.initHomes.length) {
        this.selectedYouthHomes = []
        for (const home of this.youthHomes) {
          const initHomes = this.initHomes.map(elt => +elt)
          if (existsIn([home.id], initHomes)) {
            this.selectedYouthHomes.push(home)
          }
        }
      }
    },
    initTypes() {
      if (this.initTypes && this.initTypes.length) {
        this.selectedSeanceTypes = []
        for (const elt of this.seanceTypes) {
          const initIds = this.initTypes.map(elt => +elt)
          if (existsIn([elt.id], initIds)) {
            this.selectedSeanceTypes.push(elt)
          }
        }
      }
    },
    initPeriods() {
      if (this.initPeriods && this.initPeriods.length) {
        this.selectedPeriods = []
        for (const elt of this.periods) {
          const initIds = this.initPeriods.map(elt => +elt)
          if (existsIn([elt.id], initIds)) {
            this.selectedPeriods.push(elt)
          }
        }
      }
    },
  },
  computed: {
    showPeriods() {
      if (this.multi) {
        return this.periods.length > 0
      } else {
        return this.selectedSeanceType !== null
      }
    },
    showTypes() {
      if (this.multi) {
        return this.seanceTypes.length > 0
      } else {
        return true
      }
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    loadingName() {
      return 'seances-list-filter' + this.id
    },
    seanceTypes() {
      if (this.multi) {
        return this.allSeanceTypes.filter(elt => {
          return (
            (elt.youthHomes.length === 0) ||
            (intersection(elt.youthHomes, this.selectedYouthHomes.map(elt => elt.number)).length)
          )
        })
      } else {
        if (this.selectedYouthHome) {
          return this.allSeanceTypes.filter(elt => {
            return (
              (elt.youthHomes.length === 0) ||
              (elt.youthHomes.indexOf(this.selectedYouthHome.number) >= 0)
            )
          })
        } else {
          return this.allSeanceTypes
        }
      }
    },
    periods() {
      let periods = this.allPeriods
      if (this.multi) {
        if (this.selectedSeanceTypes.length) {
          periods = periods.filter(elt => {
            return (
              (elt.youthHomes.length === 0) ||
              (intersection(elt.youthHomes, this.selectedYouthHomes.map(elt => elt.number)).length)
            )
          })
          periods = periods.filter(elt => {
            return (
              (elt.seanceTypes.length === 0) ||
              (intersection(elt.seanceTypes, this.selectedSeanceTypes.map(elt => elt.name)).length)
            )
          })
        } else {
          periods = []
        }
      } else {
        if (this.selectedYouthHome) {
          periods = periods.filter(elt => {
            return (
              (elt.youthHomes.length === 0) ||
              (elt.youthHomes.indexOf(this.selectedYouthHome.number) >= 0)
            )
          })
        }
        if (this.selectedSeanceType) {
          periods = periods.filter(elt => {
            return (
              (elt.seanceTypes.length === 0) ||
              (elt.seanceTypes.indexOf(this.selectedSeanceType.name) >= 0)
            )
          })
        }
      }
      return this.sortPeriods(periods)
    },
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    sortPeriods(periods) {
      return periods.sort(
        (elt1, elt2) => {
          if (this.selectedYouthHome) {
            let schoolYearId = this.selectedYouthHome.schoolYear.id
            let timeframe1 = elt1.getTimeframe(schoolYearId)
            let timeframe2 = elt2.getTimeframe(schoolYearId)
            let dateFrom1 = null
            let dateFrom2 = null
            if (timeframe1 && timeframe2 && timeframe1.length >= 2 && timeframe2.length >= 2) {
              dateFrom1 = timeframe1[0]
              dateFrom2 = timeframe2[0]
              return compareDays(dateFrom1, dateFrom2)
            }
          } else {
            let timeframe1 = (elt1.timeframes && elt1.timeframes.length > 0) ? elt1.timeframes[0] : null
            let timeframe2 = (elt2.timeframes && elt2.timeframes.length > 0) ? elt2.timeframes[0] : null
            if (timeframe1 && timeframe2) {
              return compareDays(timeframe1.dateFrom, timeframe2.dateFrom)
            }
          }
          return compareNumbers(elt1.id, elt2.id)
        }
      )
    },
    emitChanged() {
      this.$emit(
        'changed',
        {
          id: this.id,
          youthHome: this.selectedYouthHome,
          seanceType: this.selectedSeanceType,
          youthHomes: this.selectedYouthHomes,
          seanceTypes: this.selectedSeanceTypes,
          periods: this.selectedPeriods,
          period: this.selectedPeriod,
        }
      )
    },
    async onLoaded() {
      this.startLoading(this.loadingName)
      await this.loadYouthHomes()
      await this.loadSeanceTypes()
      await this.loadPeriods()
      this.$emit(
        'loaded',
        {
          id: this.id,
          youthHomes: this.youthHomes,
          seanceTypes: this.seanceTypes,
          periods: this.periods,
        }
      )
      this.endLoading(this.loadingName)
    },
    async loadYouthHomes() {
      let url = '/api/youth/active-youth-homes/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.youthHomes = resp.data.map(elt => makeYouthHome(elt))
        if (this.youthHomes.length === 1) {
          this.selectedYouthHome = this.youthHomes[0]
          this.selectedYouthHomes = this.youthHomes
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSeanceTypes() {
      let url = '/api/youth/seance-types/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.allSeanceTypes = resp.data.map(elt => makeSeanceType(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadPeriods() {
      let url = '/api/youth/seance-periods/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.allPeriods = resp.data.map(elt => makeSeancePeriod(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    youthHomesChanged(event) {
      this.selectedYouthHomes = event.choices
    },
    seancesTypesChanged(event) {
      this.selectedSeanceTypes = event.choices
    },
    periodsChanged(event) {
      this.selectedPeriods = event.choices
    },
    getPeriodTimeframe(period) {
      if (this.selectedYouthHome) {
        let timeframe = period.getTimeframe(this.selectedYouthHome.schoolYear.id)
        return timeframe.map(elt => moment(elt).format('ddd ll')).join(' au ')
      }
      return ''
    },
  },
  mounted() {
    this.onLoaded()
  },
}
</script>
<style lang="less">
.select-bar {
  background: #e0e0e0;
  padding: 5px;
}
.col-stacked {
  flex-basis: unset;
}
</style>
